import { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Card, Spin, Modal } from 'antd';
import ButtonComponent from "../Button";
import Icon from '../../Icon';
import ReferralForm from './ReferralForm';
import openNotification from '../Notification';

const ReferralSystem = ({ isMobile, saveFetching, promoOfferLink, getPromoOfferLink,sendReferralLink,userError,saveResult,userSaveResultReset,resetError,userErrorsReset}) => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    if (saveResult?.sendReferralLink) {
      openNotification({
        type: 'success',
        message: 'Referral link was sent',
        style: { minWidth: '716px' },
      });
        setVisible(false);
      userSaveResultReset();
    }
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [saveResult]);
    useEffect(() => {
    if (userError) {
      openNotification({
        type: 'warning',
        message: userError?.detail,
        style: { minWidth: '716px' },
      });
      resetError();
      userErrorsReset();
    }
    setVisible(false);
    /*eslint-disable-next-line react-hooks/exhaustive-deps*/
  }, [userError]);

  return (
    <>
      <Card className="account-shopify account-auto">
        <div className="account-shopify-wrap">
          <div className="account-shopify-info-block">
            <div className="shopify-header">
              <span className="account-title">
                {t('Referral system')}
              </span>
            </div>
            <div className='shopify-subtitle'>
              Some description
            </div>
          </div>
        </div>
        <div className="input-store-wrapper">
          <ButtonComponent
            type="primary"
            className='btn-store btn-store-auto'
            onClick={() => setVisible(true)}
          >
            {t('Invite referrals')}
          </ButtonComponent>
        </div>
      </Card>
      <Modal
        className="change-modal custom-modal"
        getContainer={() => document.getElementById('global-wrap')}
        {...(isMobile ? { transitionName: '' } : null)}
        open={visible}
        centered={!isMobile}
        closeIcon={
          <Icon role="icon" type="close_modal" color="#707BA0" opacity={1} />
        }
        width={438}
        title={'Referral System'}
        footer={null}
        closable="true"
        onCancel={() => setVisible(false)}
        destroyOnClose
      >
        <Spin size="large" spinning={saveFetching}>
          <ReferralForm 
            isMobile={isMobile} 
            promoOfferLink={promoOfferLink} 
            getPromoOfferLink={getPromoOfferLink} 
            sendReferralLink={sendReferralLink} 
          />
        </Spin>
      </Modal>
    </>

  )
}

export default ReferralSystem