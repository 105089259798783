/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
import React, { useState,useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Form, Divider } from 'antd';
import openNotification from '../Notification';
import Icon from '../../Icon';
import ButtonComponent from "../Button";
import InputComponent from "../Input";
import './styles-change-password.less';
import utils from '../../Utils/utils';


const ReferralForm = ({ isMobile ,promoOfferLink,getPromoOfferLink,sendReferralLink}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [errorText, setErrorText] = useState(null);
  const [emailIconColor, setEmailIconColor] = useState('#707BA0');
  const [isDisabled, setIsDisabled] = useState(true);

  useEffect(()=>{
    getPromoOfferLink()
  },[])


  const handleCopy = (value, name) => {
    navigator.clipboard.writeText(value);
    openNotification({
      message: `${name} was copied!`,
      style: { minWidth: '716px' },
    });
  };

  function handleClearError(e, field) {
    form.setFields([
      {
        name: field,
        errors: [],
      },
    ]);
    setErrorText(null);
  }

  function onFocusHandler(e) {
    handleClearError(e, e.target.name);
    setEmailIconColor('#225AEA')
  }

  const handleFinish = (values) => {
    sendReferralLink(values.email)
  };

  const handleChange = (e) => {
    const { value } = e.target;
    if (utils.validateEmail(value)) {
      setIsDisabled(false);
    } else {
      setIsDisabled(true);
    }
  };

  return (
    <Form
      form={form}
      onFinish={handleFinish}
      layout="vertical"
      style={{ margin: '0 auto' }}
    >
      <div className="copy-value">
        <span>
          Your referral link
        </span>
        <Divider type={'vertical'} style={{ margin: '0 12px' }} />
        <span
          className='copy-icon-wrapper'
          onClick={() => handleCopy(promoOfferLink, 'Referral Link')}>
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
              d="M5.8335 10.1668V13.3335C5.8335 13.7937 6.20659 14.1668 6.66683 14.1668H13.3335C13.7937 14.1668 14.1668 13.7937 14.1668 13.3335V6.66683C14.1668 6.20659 13.7937 5.8335 13.3335 5.8335H10.1668M9.3335 10.1668H2.66683C2.20659 10.1668 1.8335 9.79373 1.8335 9.3335V2.66683C1.8335 2.20659 2.20659 1.8335 2.66683 1.8335H9.3335C9.79373 1.8335 10.1668 2.20659 10.1668 2.66683V9.3335C10.1668 9.79373 9.79373 10.1668 9.3335 10.1668Z"
              stroke="#225AEA" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
          </svg>
          Copy
        </span>

      </div>

      <Form.Item
        label={t('Email')}
        className="change-form-item new-email"
        rules={[{ required: true, message: 'Required field' }]}
        validateStatus={errorText ? 'error' : null}
      >
        {errorText ? (
          <div className="confirm-message-text  error_text">
            {errorText && <Icon type={'alert_triangle_error'} role={'icon'} />}
            {errorText === 'Enter a valid email address.'
              ? 'Email address you entered is incorrect'
              : errorText}
          </div>
        ) : null}
        <Form.Item name="email" noStyle>
          <InputComponent
            size="large"
            name="email"
            type="email"
            placeholder="Email Address"
            autoComplete="off"
            prefix={<Icon type="email" color={emailIconColor} />}
            onFocus={onFocusHandler}
            onChange={handleChange}
            onBlur={() => setEmailIconColor('#707BA0')}
          />

        </Form.Item>
      </Form.Item>
      <Form.Item>
        <ButtonComponent
          type="primary"
          htmlType="submit"
          className="btn-primary"
          style={{ width: '100%', margin: 0 }}
          disabled={isDisabled || !form.getFieldValue('email')}
        >
          {t('Invite')}
        </ButtonComponent>
      </Form.Item>
    </Form>
  );
};

export default ReferralForm;
